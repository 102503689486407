<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>訊息</CCardHeader>
        <CCardBody>歡迎使用永澄環境衛生工程系統，請由左方選單進入系統。</CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
